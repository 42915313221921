import * as types from "../constants/Account";

const Account = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.ACCOUNT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: action.accounts,
      };
    case types.ACCOUNT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ACCOUNT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.account.reference,
      };
    case types.ACCOUNT_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ACCOUNT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.account.reference,
      };
    case types.ACCOUNT_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ACCOUNT_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        account: action.account,
      };
    case types.ACCOUNT_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ACCOUNT_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reference: "deleted",
        accounts: {
          ...state.accounts,
          content: state.accounts.content.filter(
            (at: any): any => at.id !== action.id
          ),
        },
      };
    case types.ACCOUNT_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    case types.ACCOUNT_FORM_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_FORM_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        accountTypes: action.formData.accountTypes,
        assetManagers: action.formData.assetManagers,
        accountCodes: action.formData.accountCodes,
      };
    case types.ACCOUNT_FORM_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    case types.ACCOUNT_SECURITIES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_SECURITIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        accountSecurities: action.accountSecurities,
      };
    case types.ACCOUNT_SECURITIES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ALL_ACCOUNT_SECURITIES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ALL_ACCOUNT_SECURITIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allAccountSecurities: action.allAccountSecurities,
      };
    case types.ALL_ACCOUNT_SECURITIES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SECURITY_START_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SECURITY_START_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        securityStartDate: action.date,
      };
    case types.SECURITY_START_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ACCOUNT_START_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_START_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        accountStartDate: action.date,
      };
    case types.ACCOUNT_START_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ACCOUNT_VALUES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_VALUES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        accountValues: action.accountValues,
      };
    case types.ACCOUNT_VALUES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ACCOUNT_SAVE_VALUES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_SAVE_VALUES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.ACCOUNT_SAVE_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.messages,
      };
    case types.ACCOUNT_SAVE_SECURITY_LIFETIME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_SAVE_SECURITY_LIFETIME_SUCCESS:
      return {
        ...state,
        loading: false,
        lifetimeSaved: true,
        success: true,
        accountSecurityLifetime:
          action.accountSecurityLifetime.accountSecurityLifetime,
        id: action.accountSecurityLifetime.objectId,
      };
    case types.ACCOUNT_SAVE_SECURITY_LIFETIME_FAILURE:
      return {
        ...state,
        loading: false,
        lifetimeSaved: false,
        success: false,
        error: action.error,
      };
    case types.ACCOUNT_SECURITY_LIFETIME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_SECURITY_LIFETIME_SUCCESS:
      return {
        ...state,
        loading: false,
        accountSecurityLifetimes: action.securityLifetimes,
      };
    case types.ACCOUNT_SECURITY_LIFETIME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.messages,
      };
    case types.ACCOUNT_ATTACH_SECURITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_ATTACH_SECURITY_SUCCESS:
      return {
        ...state,
        loading: false,
        accountSecurity: action.accountSecurity,
        success: true,
      };
    case types.ACCOUNT_ATTACH_SECURITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    case types.ACCOUNT_SECURITY_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_SECURITY_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        success: true,
      };
    case types.ACCOUNT_SECURITY_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
        deleted: false,
      };
    case types.ACCOUNT_REMOVE_SECURITY_LIFETIME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_REMOVE_SECURITY_LIFETIME_SUCCESS:
      return {
        ...state,
        loading: false,
        lifetimeDeleted: true,
        id: action.id,
      };
    case types.ACCOUNT_REMOVE_SECURITY_LIFETIME_FAILURE:
      return {
        ...state,
        loading: false,
        lifetimeDeleted: false,
        error: action.error,
      };
    case types.ACCOUNT_CHECK_TO_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_CHECK_TO_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.ACCOUNT_CHECK_TO_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.ACCOUNT_SECURITY_CHECK_TO_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_SECURITY_CHECK_TO_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.ACCOUNT_SECURITY_CHECK_TO_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.ACCOUNT_INFERRED_VALUES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_INFERRED_VALUES_SUCCESS:
      return {
        ...state,
        loading: false,
        accountInferredValues: action.accountInferredValues,
      };
    case types.ACCOUNT_INFERRED_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        accountInferredValues: undefined,
      };
    case types.ACCOUNT_SAVE_INFERRED_VALUES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_SAVE_INFERRED_VALUES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.ACCOUNT_SAVE_INFERRED_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.messages,
      };
    default:
      return state;
  }
};

export default Account;
