import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AccountActions from "../../actions/AccountActions";
import AssetTableActions from "../../actions/AssetTableActions";
import DatePickerActions from "../../actions/DatePickerActions";
import AccountDetails from "./AccountDetails";

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    { ...AccountActions, ...AssetTableActions, ...DatePickerActions },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  accountValues: state.Account.accountValues,
  account: state.Account.account,
  id: state.Account.id,
  deleted: state.AccountType.deleted,
  accountType: state.AccountType.accountType,
  success: state.Account.success,
  error: state.Account.error,
  loading: state.Account.loading,
  accountStartDate: state.Account.accountStartDate,
  selectedYear: state.DatePicker.year,
  selectedMonth: state.DatePicker.month,
  inferredAccountValues: state.Account.accountInferredValues,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
