export const getUrl = (endpoint: string): string => {
  let url = `https://kaya2.iassetmonitor.com/api/${endpoint}`;

  if (process.env.LOCAL_API) {
    url = `http://localhost:8091/${endpoint}`;
  }

  if (process.env.BACKEND_URL) {
    url = `https://${process.env.BACKEND_URL}/api/${endpoint}`;
  }

  if (window.location.hostname) {
  }

  url = `https://${window.location.hostname}/api/${endpoint}`;
  // url = `http://localhost:8091/${endpoint}`;

  return url;
};

const Request = (
  endpoint: string,
  method: string,
  data?: any,
  params?: any,
  headers?: any
): any => {
  let url = getUrl(endpoint);

  if (params) {
    let paramsString = "";
    Object.keys(params).forEach((param, index) => {
      if (index > 0) {
        paramsString += "&";
      }
      paramsString += `${param}=${params[param]}`;
    });
    url += `?${paramsString}`;
  }

  const requestOptions: any = {
    method,
    headers: {
      ...headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  if (data) {
    requestOptions.body = JSON.stringify(data);
  }

  return fetch(url, requestOptions);
};

export default Request;
