import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccountActions from '../../actions/AccountActions';
import AccountTypeActions from '../../actions/AccountTypeActions';
import AssetClassSetActions from '../../actions/AssetClassSetActions';
import AccountCodeActions from '../../actions/AccountCodeActions';
import AssetManagerActions from '../../actions/AssetManagerActions';
import ClientActions from '../../actions/ClientActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ExportActions from '../../actions/ExportActions';
import ClientAccounts from './ClientAccounts';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...ClientActions,
      ...UserActions,
      ...AssetClassSetActions,
      ...DialogActions,
      ...ExportActions,
      ...AccountCodeActions,
      ...AssetManagerActions,
      ...AccountTypeActions,
      ...AccountActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  accounts: state.Account.accounts,
  accountTypes: state.AccountType.accountTypes,
  clients: state.Client.clients,
  assetManagers: state.AssetManager.assetManagers,
  accountCodes: state.AccountCode.accountCodes,
  assetClassSets: state.AssetClassSet.assetClassSets,
  deleted: state.Account.deleted,
  accountType: state.AccountType.accountType,
  success: state.Account.success,
  error: state.Account.error,
  loading: state.Account.loading,
  client: state.Client.client,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientAccounts);
